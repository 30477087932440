enifed('ember-glimmer/environment', ['exports', 'ember-babel', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-views', '@glimmer/runtime', 'ember-glimmer/component-managers/curly', 'ember-glimmer/syntax', 'ember-glimmer/utils/iterable', 'ember-glimmer/utils/references', 'ember-glimmer/utils/debug-stack', 'ember-glimmer/helpers/if-unless', 'ember-glimmer/helpers/action', 'ember-glimmer/helpers/component', 'ember-glimmer/helpers/concat', 'ember-glimmer/helpers/get', 'ember-glimmer/helpers/hash', 'ember-glimmer/helpers/loc', 'ember-glimmer/helpers/log', 'ember-glimmer/helpers/mut', 'ember-glimmer/helpers/readonly', 'ember-glimmer/helpers/unbound', 'ember-glimmer/helpers/-class', 'ember-glimmer/helpers/-input-type', 'ember-glimmer/helpers/query-param', 'ember-glimmer/helpers/each-in', 'ember-glimmer/helpers/-normalize-class', 'ember-glimmer/helpers/-html-safe', 'ember-glimmer/protocol-for-url', 'ember-glimmer/modifiers/action', 'ember/features'], function (exports, _emberBabel, _emberUtils, _emberMetal, _emberDebug, _emberViews, _runtime, _curly, _syntax, _iterable, _references, _debugStack, _ifUnless, _action, _component, _concat, _get, _hash, _loc, _log, _mut, _readonly, _unbound, _class, _inputType, _queryParam, _eachIn, _normalizeClass, _htmlSafe, _protocolForUrl, _action2) {
  'use strict';

  function instrumentationPayload(name) {
    return { object: 'component:' + name };
  }

  var Environment = function (_GlimmerEnvironment) {
    (0, _emberBabel.inherits)(Environment, _GlimmerEnvironment);

    Environment.create = function (options) {
      return new this(options);
    };

    function Environment(_ref) {
      var owner = _ref[_emberUtils.OWNER];


      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _GlimmerEnvironment.apply(this, arguments));

      _this.owner = owner;
      _this.isInteractive = owner.lookup('-environment:main').isInteractive;

      // can be removed once https://github.com/tildeio/glimmer/pull/305 lands
      _this.destroyedComponents = [];

      (0, _protocolForUrl.default)(_this);

      _this._definitionCache = new _emberMetal.Cache(2000, function (_ref2) {
        var name = _ref2.name,
            source = _ref2.source,
            owner = _ref2.owner;

        var _lookupComponent = (0, _emberViews.lookupComponent)(owner, name, { source: source }),
            componentFactory = _lookupComponent.component,
            layout = _lookupComponent.layout;

        var customManager = undefined;

        if (componentFactory || layout) {
          return new _curly.CurlyComponentDefinition(name, componentFactory, layout, undefined, customManager);
        }
      }, function (_ref3) {
        var name = _ref3.name,
            source = _ref3.source,
            owner = _ref3.owner;

        var expandedName = source && _this._resolveLocalLookupName(name, source, owner) || name;

        var ownerGuid = (0, _emberUtils.guidFor)(owner);

        return ownerGuid + '|' + expandedName;
      });

      _this._templateCache = new _emberMetal.Cache(1000, function (_ref4) {
        var Template = _ref4.Template,
            owner = _ref4.owner,
            _Template$create;

        if (Template.create) {

          // we received a factory
          return Template.create((_Template$create = { env: _this }, _Template$create[_emberUtils.OWNER] = owner, _Template$create));
        } else {
          // we were provided an instance already
          return Template;
        }
      }, function (_ref5) {
        var Template = _ref5.Template,
            owner = _ref5.owner;
        return (0, _emberUtils.guidFor)(owner) + '|' + Template.id;
      });

      _this._compilerCache = new _emberMetal.Cache(10, function (Compiler) {
        return new _emberMetal.Cache(2000, function (template) {
          var compilable = new Compiler(template);
          return (0, _runtime.compileLayout)(compilable, _this);
        }, function (template) {
          var owner = template.meta.owner;
          return (0, _emberUtils.guidFor)(owner) + '|' + template.id;
        });
      }, function (Compiler) {
        return Compiler.id;
      });

      _this.builtInModifiers = {
        action: new _action2.default()
      };

      _this.builtInHelpers = {
        if: _ifUnless.inlineIf,
        action: _action.default,
        concat: _concat.default,
        get: _get.default,
        hash: _hash.default,
        loc: _loc.default,
        log: _log.default,
        mut: _mut.default,
        'query-params': _queryParam.default,
        readonly: _readonly.default,
        unbound: _unbound.default,
        unless: _ifUnless.inlineUnless,
        '-class': _class.default,
        '-each-in': _eachIn.default,
        '-input-type': _inputType.default,
        '-normalize-class': _normalizeClass.default,
        '-html-safe': _htmlSafe.default,
        '-get-dynamic-var': _runtime.getDynamicVar
      };

      return _this;
    }

    Environment.prototype._resolveLocalLookupName = function (name, source, owner) {
      return owner._resolveLocalLookupName(name, source);
    };

    Environment.prototype.macros = function () {
      var macros = _GlimmerEnvironment.prototype.macros.call(this);
      (0, _syntax.populateMacros)(macros.blocks, macros.inlines);
      return macros;
    };

    Environment.prototype.hasComponentDefinition = function () {
      return false;
    };

    Environment.prototype.getComponentDefinition = function (name, _ref6) {
      var owner = _ref6.owner,
          moduleName = _ref6.moduleName;

      var finalizer = (0, _emberMetal._instrumentStart)('render.getComponentDefinition', instrumentationPayload, name);

      var definition = this._definitionCache.get({ name: name, source: moduleName && 'template:' + moduleName, owner: owner });
      finalizer();
      return definition;
    };

    Environment.prototype.getTemplate = function (Template, owner) {
      return this._templateCache.get({ Template: Template, owner: owner });
    };

    Environment.prototype.getCompiledBlock = function (Compiler, template) {
      var compilerCache = this._compilerCache.get(Compiler);
      return compilerCache.get(template);
    };

    Environment.prototype.hasPartial = function (name, _ref7) {
      var owner = _ref7.owner;

      return (0, _emberViews.hasPartial)(name, owner);
    };

    Environment.prototype.lookupPartial = function (name, _ref8) {
      var owner = _ref8.owner;

      var partial = {
        template: (0, _emberViews.lookupPartial)(name, owner)
      };

      if (partial.template) {
        return partial;
      } else {
        throw new Error(name + ' is not a partial');
      }
    };

    Environment.prototype.hasHelper = function (name, _ref9) {
      var owner = _ref9.owner,
          moduleName = _ref9.moduleName;

      if (name === 'component' || this.builtInHelpers[name]) {
        return true;
      }

      return owner.hasRegistration('helper:' + name, { source: 'template:' + moduleName }) || owner.hasRegistration('helper:' + name);
    };

    Environment.prototype.lookupHelper = function (name, meta) {
      if (name === 'component') {
        return function (vm, args) {
          return (0, _component.default)(vm, args, meta);
        };
      }

      var owner = meta.owner,
          moduleName = meta.moduleName;

      var helper = this.builtInHelpers[name];

      if (helper) {
        return helper;
      }

      var helperFactory = owner.factoryFor('helper:' + name, moduleName && { source: 'template:' + moduleName } || {}) || owner.factoryFor('helper:' + name);

      // TODO: try to unify this into a consistent protocol to avoid wasteful closure allocations
      var HelperReference = void 0;
      if (helperFactory.class.isSimpleHelperFactory) {
        HelperReference = _references.SimpleHelperReference;
      } else if (helperFactory.class.isHelperFactory) {
        HelperReference = _references.ClassBasedHelperReference;
      } else {
        throw new Error(name + ' is not a helper');
      }

      return function (vm, args) {
        return HelperReference.create(helperFactory, vm, args.capture());
      };
    };

    Environment.prototype.hasModifier = function (name) {
      return !!this.builtInModifiers[name];
    };

    Environment.prototype.lookupModifier = function (name) {
      var modifier = this.builtInModifiers[name];

      if (modifier) {
        return modifier;
      } else {
        throw new Error(name + ' is not a modifier');
      }
    };

    Environment.prototype.toConditionalReference = function (reference) {
      return _references.ConditionalReference.create(reference);
    };

    Environment.prototype.iterableFor = function (ref, key) {
      return (0, _iterable.default)(ref, key);
    };

    Environment.prototype.scheduleInstallModifier = function () {
      var _GlimmerEnvironment$p;

      if (this.isInteractive) {

        (_GlimmerEnvironment$p = _GlimmerEnvironment.prototype.scheduleInstallModifier).call.apply(_GlimmerEnvironment$p, [this].concat(Array.prototype.slice.call(arguments)));
      }
    };

    Environment.prototype.scheduleUpdateModifier = function () {
      var _GlimmerEnvironment$p2;

      if (this.isInteractive) {

        (_GlimmerEnvironment$p2 = _GlimmerEnvironment.prototype.scheduleUpdateModifier).call.apply(_GlimmerEnvironment$p2, [this].concat(Array.prototype.slice.call(arguments)));
      }
    };

    Environment.prototype.didDestroy = function (destroyable) {
      destroyable.destroy();
    };

    Environment.prototype.begin = function () {
      this.inTransaction = true;

      _GlimmerEnvironment.prototype.begin.call(this);
    };

    Environment.prototype.commit = function () {
      var destroyedComponents = this.destroyedComponents,
          i;
      this.destroyedComponents = [];
      // components queued for destruction must be destroyed before firing
      // `didCreate` to prevent errors when removing and adding a component
      // with the same name (would throw an error when added to view registry)
      for (i = 0; i < destroyedComponents.length; i++) {
        destroyedComponents[i].destroy();
      }

      try {
        _GlimmerEnvironment.prototype.commit.call(this);
      } finally {
        this.inTransaction = false;
      }
    };

    return Environment;
  }(_runtime.Environment);

  exports.default = Environment;
});