define('ember-context-menu/components/context-menu-item', ['exports', 'ember-context-menu/templates/components/context-menu-item', 'ember-invoke-action', 'ember-component', 'ember-computed', 'ember-metal/get'], function (exports, _emberContextMenuTemplatesComponentsContextMenuItem, _emberInvokeAction, _emberComponent, _emberComputed, _emberMetalGet) {
  exports['default'] = _emberComponent['default'].extend({
    layout: _emberContextMenuTemplatesComponentsContextMenuItem['default'],

    tagName: 'li',

    classNames: ['context-menu__item'],
    classNameBindings: ['isDisabled:context-menu__item--disabled', '_isParent:context-menu__item--parent'],

    _amount: (0, _emberComputed['default'])('_isParent', 'amount', function () {
      var amount = (0, _emberMetalGet['default'])(this, 'amount');

      return !(0, _emberMetalGet['default'])(this, '_isParent') && amount > 1 && amount;
    }),

    _isParent: (0, _emberComputed.bool)('item.subActions.length'),

    isDisabled: (0, _emberComputed['default'])('item.{disabled,action}', 'itemIsDisabled', function () {
      var item = (0, _emberMetalGet['default'])(this, 'item');
      return (0, _emberInvokeAction['default'])(this, 'itemIsDisabled', item);
    }),

    click: function click() {
      if (!(0, _emberMetalGet['default'])(this, 'isDisabled') && !(0, _emberMetalGet['default'])(this, '_isParent')) {
        (0, _emberInvokeAction['default'])(this, 'clickAction', (0, _emberMetalGet['default'])(this, 'item'));
      }
    }
  });
});