define('ember-context-menu/services/context-menu', ['exports', 'ember-service', 'ember-metal/utils', 'ember-metal/get', 'ember-metal/set'], function (exports, _emberService, _emberMetalUtils, _emberMetalGet, _emberMetalSet) {

  function renderLeft(xPosition, screenWidth) {
    if (!xPosition || !screenWidth) {
      return false;
    }

    var onRightHalf = xPosition > screenWidth * 0.5;
    var spaceRight = screenWidth - xPosition;

    return onRightHalf && spaceRight < 400;
  }

  exports['default'] = _emberService['default'].extend({
    isActive: false,

    activate: function activate(event, items, selection, details) {
      var clientX = event.clientX;
      var clientY = event.clientY;

      var screenWidth = (0, _emberMetalGet['default'])(event, 'view.window.innerWidth');

      selection = selection ? [].concat(selection) : [];

      this.removeDeactivateHandler();

      if (clientX == null || clientY == null) {
        (0, _emberMetalUtils.assert)('You need to pass event to the context-menu activate()');
      }

      if (!(items && items.length)) {
        (0, _emberMetalUtils.assert)('You need to pass items to the context-menu activate()');
      }

      (0, _emberMetalSet['default'])(this, 'position', {
        left: clientX,
        top: clientY
      });

      (0, _emberMetalSet['default'])(this, 'event', event);
      (0, _emberMetalSet['default'])(this, 'items', items);
      (0, _emberMetalSet['default'])(this, 'selection', selection);
      (0, _emberMetalSet['default'])(this, 'details', details);
      (0, _emberMetalSet['default'])(this, 'renderLeft', renderLeft(clientX, screenWidth));
      (0, _emberMetalSet['default'])(this, 'isActive', true);

      this.addDeactivateHandler();
    },

    willDestroy: function willDestroy() {
      this.removeDeactivateHandler();
    },

    removeDeactivateHandler: function removeDeactivateHandler() {
      var deactivate = (0, _emberMetalGet['default'])(this, 'deactivate');

      if (deactivate != null) {
        $(document.body).off('click', deactivate);
        (0, _emberMetalSet['default'])(this, 'deactivate', null);
      }
    },

    addDeactivateHandler: function addDeactivateHandler() {
      var _this = this;

      var deactivate = function deactivate() {
        return (0, _emberMetalSet['default'])(_this, 'isActive', false);
      };
      (0, _emberMetalSet['default'])(this, 'deactivate', deactivate);

      $(document.body).one('click', deactivate);
    }
  });
});