define('ember-context-menu/mixins/context-menu', ['exports', 'ember-metal/mixin', 'ember-invoke-action', 'ember-service/inject', 'ember-metal/get'], function (exports, _emberMetalMixin, _emberInvokeAction, _emberServiceInject, _emberMetalGet) {
  exports['default'] = _emberMetalMixin['default'].create({
    contextMenuService: (0, _emberServiceInject['default'])('context-menu'),

    contextMenu: function contextMenu(e) {
      (0, _emberInvokeAction['default'])(this, '_contextMenu', e);

      var contextMenu = (0, _emberMetalGet['default'])(this, 'contextMenuService');
      var items = (0, _emberMetalGet['default'])(this, 'contextItems');
      var selection = (0, _emberMetalGet['default'])(this, 'contextSelection');
      var details = (0, _emberMetalGet['default'])(this, 'contextDetails');

      if (items && (0, _emberMetalGet['default'])(items, 'length')) {
        e.preventDefault();
        contextMenu.activate(e, items, selection, details);
      }
    }
  });
});