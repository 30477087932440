enifed('ember-glimmer/helper', ['exports', 'ember-utils', 'ember-runtime', '@glimmer/reference'], function (exports, _emberUtils, _emberRuntime, _reference) {
  'use strict';

  exports.SimpleHelper = exports.RECOMPUTE_TAG = undefined;
  exports.helper =

  /**
    In many cases, the ceremony of a full `Helper` class is not required.
    The `helper` method create pure-function helpers without instances. For
    example:
  
    ```app/helpers/format-currency.js
    import { helper } from '@ember/component/helper';
  
    export default helper(function(params, hash) {
      let cents = params[0];
      let currency = hash.currency;
      return `${currency}${cents * 0.01}`;
    });
    ```
  
    @static
    @param {Function} helper The helper function
    @method helper
    @for @ember/component/helper
    @public
    @since 1.13.0
  */
  function (helperFn) {
    return new SimpleHelper(helperFn);
  };
  var RECOMPUTE_TAG = exports.RECOMPUTE_TAG = (0, _emberUtils.symbol)('RECOMPUTE_TAG');

  /**
    Ember Helpers are functions that can compute values, and are used in templates.
    For example, this code calls a helper named `format-currency`:
  
    ```handlebars
    <div>{{format-currency cents currency="$"}}</div>
    ```
  
    Additionally a helper can be called as a nested helper (sometimes called a
    subexpression). In this example, the computed value of a helper is passed
    to a component named `show-money`:
  
    ```handlebars
    {{show-money amount=(format-currency cents currency="$")}}
    ```
  
    Helpers defined using a class must provide a `compute` function. For example:
  
    ```app/helpers/format-currency.js
    import Helper from '@ember/component/helper';
  
    export default Helper.extend({
      compute(params, hash) {
        let cents = params[0];
        let currency = hash.currency;
        return `${currency}${cents * 0.01}`;
      }
    });
    ```
  
    Each time the input to a helper changes, the `compute` function will be
    called again.
  
    As instances, these helpers also have access to the container an will accept
    injected dependencies.
  
    Additionally, class helpers can call `recompute` to force a new computation.
  
    @class Helper
    @public
    @since 1.13.0
  */
  /**
  @module @ember/component
  */

  var Helper = _emberRuntime.FrameworkObject.extend({
    isHelperInstance: true,

    init: function () {
      this._super.apply(this, arguments);
      this[RECOMPUTE_TAG] = new _reference.DirtyableTag();
    },
    recompute: function () {
      this[RECOMPUTE_TAG].dirty();
    }

    /**
      Override this function when writing a class-based helper.
       @method compute
      @param {Array} params The positional arguments to the helper
      @param {Object} hash The named arguments to the helper
      @public
      @since 1.13.0
    */
  });

  Helper.reopenClass({
    isHelperFactory: true
  });

  var SimpleHelper = exports.SimpleHelper = function () {
    function SimpleHelper(compute) {

      this.isHelperFactory = true;
      this.isHelperInstance = true;
      this.isSimpleHelperFactory = true;
      this.compute = compute;
    }

    SimpleHelper.prototype.create = function () {
      return this;
    };

    return SimpleHelper;
  }();

  exports.default = Helper;
});