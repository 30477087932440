define('ember-context-menu/components/context-menu', ['exports', 'ember-context-menu/templates/components/context-menu', 'ember-invoke-action', 'ember-component', 'ember-service/inject', 'ember-string', 'ember-computed', 'ember-metal/get'], function (exports, _emberContextMenuTemplatesComponentsContextMenu, _emberInvokeAction, _emberComponent, _emberServiceInject, _emberString, _emberComputed, _emberMetalGet) {
  exports['default'] = _emberComponent['default'].extend({
    layout: _emberContextMenuTemplatesComponentsContextMenu['default'],

    contextMenu: (0, _emberServiceInject['default'])('context-menu'),

    isActive: (0, _emberComputed.reads)('contextMenu.isActive'),
    renderLeft: (0, _emberComputed.reads)('contextMenu.renderLeft'),
    items: (0, _emberComputed.reads)('contextMenu.items'),
    _selection: (0, _emberComputed.reads)('contextMenu.selection'),
    details: (0, _emberComputed.reads)('contextMenu.details'),
    clickEvent: (0, _emberComputed.reads)('contextMenu.event'),

    selection: (0, _emberComputed['default'])('_selection.[]', function () {
      return [].concat((0, _emberMetalGet['default'])(this, '_selection'));
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setWormholeTarget();
    },

    setWormholeTarget: function setWormholeTarget() {
      var id = 'wormhole-context-menu';
      var $target = $('#' + id);
      if ($target.length === 0) {
        $('body').append('<div id="' + id + '"></div>');
      }
    },

    position: (0, _emberComputed['default'])('contextMenu.position.{left,top}', function () {
      var _ref = (0, _emberMetalGet['default'])(this, 'contextMenu.position') || {};

      var left = _ref.left;
      var top = _ref.top;

      return (0, _emberString.htmlSafe)('left: ' + left + 'px; top: ' + top + 'px;');
    }),

    itemIsDisabled: (0, _emberComputed['default'])('selection.[]', 'details', function () {
      var selection = (0, _emberMetalGet['default'])(this, 'selection') || [];
      var details = (0, _emberMetalGet['default'])(this, 'details');

      return function (item) {
        var disabled = (0, _emberMetalGet['default'])(item, 'disabled');

        if (!(0, _emberMetalGet['default'])(item, 'action') && !(0, _emberMetalGet['default'])(item, 'subActions')) {
          return true;
        }

        if (typeof disabled === 'function') {
          return disabled(selection, details);
        }

        return disabled;
      };
    }),

    clickAction: (0, _emberComputed['default'])('selection.[]', 'details', function () {
      var selection = (0, _emberMetalGet['default'])(this, 'selection');
      var details = (0, _emberMetalGet['default'])(this, 'details');
      var event = (0, _emberMetalGet['default'])(this, 'clickEvent');

      return function (item) {
        (0, _emberInvokeAction['default'])(item, 'action', selection, details, event);
      };
    })
  });
});